import $ from 'jquery'
import programLandingSlider from '../components/programLandingSlider'

var signals

module.exports = {
  init: function (config) {
    console.log('  === programs-landing ===')
    signals = config.signals
    programLandingSlider.init(config)
    signals['domReady'].dispatch()
  }
}
