import $ from 'jquery'

const Flickity = require('flickity')

var signals
const figcaptions = []
let $figcaption

const setFigcaption = ({ index }) => {
  $figcaption.html(figcaptions[index])
}

const handleReady = () => {
  setFigcaption({
    index: 0
  })
}

const handleChange = (index) => {
  $figcaption.addClass('hide')
}

const handleSettle = (index) => {
  setFigcaption({ index })
  $figcaption.removeClass('hide')
}

module.exports = {
  init: function (config) {
    console.log('  === program-landing-slider ===')

    $figcaption = $('figcaption')
    const elem = document.querySelector('.program-landing-slider')
    if (elem && $figcaption.length) {
      // grab all figcaptions
      $('.program-landing-slide img').each((index, el) => {
        figcaptions.push($(el).data('caption'))
      })

      const flkty = new Flickity(elem, {
        contain: true,
        cellSelector: '.program-landing-slide',
        prevNextButtons: false,
        pageDots: true,
        wrapAround: false,
        on: {
          ready: handleReady,
          change: handleChange,
          settle: handleSettle
        }
      })

      setTimeout(function () {
        flkty.resize()
      }, 1000)
    }

    signals = config.signals
  }
}
