import $ from 'jquery'
import input from '../components/forms/input'
import select from '../components/forms/select'
import checkbox from '../components/forms/checkbox'
import radio from '../components/forms/radio'
import BlenderboxSelect from '../components/blenderbox-select'

var signals
var selectSelector = '.bbox-select'
var selectInstances = []

const handleItemSelected = ({ id, type, selected }) => {
  console.log('handleItemSelected')
  console.log('id', id)
  console.log('type', type)
  console.log(
    'selected',
    selected.filter((n) => n.selected)
  )
}

module.exports = {
  init: function (config) {
    console.log('  === styleguide ===')
    signals = config.signals

    input.init(config)
    select.init(config)
    checkbox.init(config)
    radio.init(config)

    signals.itemSelected.add(handleItemSelected)

    const $selects = $(selectSelector)
    if ($selects.length) {
      $selects.each((i, el) => {
        selectInstances[i] = new BlenderboxSelect(signals, $(el))
        selectInstances[i].init()
      })
    }

    signals['domReady'].dispatch()
  }
}
