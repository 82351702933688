import $ from 'jquery'

var signals

module.exports = {
  init: function (config) {
    console.log('  === third-level-page ===')
    signals = config.signals
    signals['domReady'].dispatch()
  }
}
