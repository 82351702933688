import $ from 'jquery'

let $allPanels
let $allSvgs

const toggleAccordion = (event) => {
  const $this = $(event.currentTarget)
  const $svg = $this.find('svg')
  const $target = $this.parent().next()

  // if we're not currently active...
  if (!$target.hasClass('active')) {
    // collapse/reset all of them
    $allPanels.removeClass('active').slideUp()
    $allPanels.attr('aria-expanded', 'false')
    $allSvgs.removeClass('rotate')

    // make this one active, expand, rotate SVG
    $target.addClass('active').slideDown()
    $target.attr('aria-expanded', 'true')
    $svg.addClass('rotate')
  } else {
    // ... otherwise we are active, so let's collapse this one
    $target.removeClass('active').slideUp()
    $target.attr('aria-expanded', 'false')
    $svg.removeClass('rotate')
  }
}

module.exports = {
  init: function () {
    console.log('  === accordion ===')

    $allPanels = $('.accordion > dd').hide()
    $allSvgs = $('.accordion > dt svg')

    $('.accordion > dt > button').click(toggleAccordion)
  }
}
