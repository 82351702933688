import $ from 'jquery'
import homepageCarousel from '../components/homepage-carousel'

var signals

const imageCycle = () => {
  setTimeout(function () {
    $('.billboard-images img:nth-of-type(2)').addClass('show')
  }, 5000)
  setTimeout(function () {
    $('.billboard-images img:nth-of-type(3)').addClass('show')
  }, 10000)
  setTimeout(function () {
    $('.billboard-images img:nth-of-type(4)').addClass('show')
  }, 15000)
  setTimeout(function () {
    $('.billboard-images img:nth-of-type(1)').addClass('show')
    $(
      '.billboard-images img:nth-of-type(2), .billboard-images img:nth-of-type(3), .billboard-images img:nth-of-type(4)'
    ).removeClass('show')
  }, 20000)
}

// $(document).ready( function() {
//   imageCycle();
//   setInterval( function() {
//     imageCycle();
//   }, 20000);
// });

module.exports = {
  init: function (config) {
    console.log('  === home ===')
    signals = config.signals

    homepageCarousel.init(config)
    // imageCycle();
    // setInterval( function() {
    //   imageCycle();
    // }, 20000);

    signals['domReady'].dispatch()
  }
}
