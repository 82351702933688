import $ from 'jquery'

var signals

window.jQuery = window.$ = $

require('waypoints/lib/jquery.waypoints')
require('waypoints/lib/shortcuts/sticky')

const handleExpandCollapse = ({ expandDuration }) => {
  setTimeout(function () {
    Waypoint.refreshAll()
  }, expandDuration)
}

const mobileSubnavToggle = () => {
  $('.subnav-programs ul').slideToggle(300)
  $('.subnav-mobile-label').toggleClass('active')
}

module.exports = {
  init: function (config) {
    console.log('  === sticky program header ===')

    // calculate program hero height offset
    const headerHeight = $('header.header').height()
    const subnavProgramsHeight = $('.subnav-programs').height()
    const programHeroHeight = $('.program-hero').height()
    const programHeroOffset = 70
    const articleContentOffest =
      headerHeight +
      subnavProgramsHeight +
      programHeroHeight +
      programHeroOffset
    // $('.article-content').css('margin-top', articleContentOffest);

    // sticky column headers
    // first
    if ($('.program-article').length) {
      const stickyColumnHeader = new Waypoint({
        element: $('.program-article')[0],
        handler: function (direction) {
          $('article.program-article:nth-of-type(1)').toggleClass(
            'first-waypoint'
          )
          $('.back-to-top').toggleClass('show')
          $('.program-hero').toggleClass('hide')
          $('.program-title').toggleClass('fixed')
        },
        // offset: 240,
        offset: 225
      })
    }

    // all others
    $('.program-article')
      .not('.program-article:nth-of-type(1)')
      .each(function () {
        var self = $(this)
        $(this).waypoint({
          handler: function (direction) {
            self.toggleClass('waypoint')
          },
          // offset: 190,
          offset: 180
        })
      })

    // back to top
    $('.back-to-top').click(function (e) {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        300
      )
    })

    if ($('.module--3-up').length) {
      const toggleSubnavAtFooter = new Waypoint({
        element: $('.module--3-up'),
        handler: function (direction) {
          $('.subnav-mobile-label').toggleClass('footer-view')
        },
        offset: 'bottom-in-view'
      })
    }

    if (matchMedia('(min-width: 769px)').matches && $('.footer').length) {
      const toggleStickyAtFooter = new Waypoint({
        element: $('.footer'),
        handler: function (direction) {
          $('article.program-article:nth-of-type(1)').toggleClass(
            'first-waypoint'
          )
          $('.program-article')
            .not('.program-article:nth-of-type(1)')
            .toggleClass('waypoint')
          $('.program-title').toggleClass('fixed')
        },
        offset: 380
      })
    }

    // this one need to run only in mobile to avoid header and footer overlap
    if (
      matchMedia('(max-width: 768px)').matches &&
      $('.program-article:last-of-type').length > 0
    ) {
      const toggleArticleAtFooter = new Waypoint({
        element: $('.program-article:last-of-type')[0],
        handler: function (direction) {
          $('article.program-article:nth-of-type(1)').toggleClass(
            'first-waypoint'
          )
          $('.program-article')
            .not('.program-article:nth-of-type(1)')
            .toggleClass('waypoint')
          $('.program-title').toggleClass('fixed')
        },
        offset: 'bottom-in-view'
      })
    }

    // const hideBackToTopAtFooter = new Waypoint({
    //   element: $('.program-article:last-of-type')[0],
    //   handler: function(direction) {
    //     $('.back-to-top').toggleClass('show');
    //   },
    //   offset: 'bottom-in-view',
    // });

    $('.subnav-mobile-label').click(mobileSubnavToggle)

    signals = config.signals
    signals.expandCollapse.add(handleExpandCollapse)
  }
}
