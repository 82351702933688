import $ from 'jquery'
import stickyProgramHeader from '../components/programHeader'

var signals

module.exports = {
  init: function (config) {
    console.log('  === luce-scholar-experience-landing ===')
    signals = config.signals
    stickyProgramHeader.init(config)
    signals['domReady'].dispatch()
  }
}
