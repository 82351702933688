import $ from 'jquery'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import geojsonExtent from 'geojson-extent'

import checkbox from '../components/forms/checkbox'

var signals
let map
let areasOfProfessionalInterest = []
let markers = {}

const removeMarkers = () => {
  Object.keys(markers).forEach((key) => {
    markers[key].remove()
  })
  markers = {}
}

const drawMapMarkers = ({ geoJSON }) => {
  if (map) {
    map.addSource('scholars', {
      type: 'geojson',
      data: geoJSON
    })

    // merge any 'features' that have the same lng/lat
    const merged = {}
    geoJSON.features.forEach((feature) => {
      const { coordinates } = feature.geometry
      const {
        firstName,
        lastName,
        image,
        year,
        areaOfProfessionalInterest,
        nominatingInstitution,
        placementInstitution,
        city,
        country
      } = feature.properties

      const key = coordinates.join('|')
      if (merged[key]) {
        merged[key].properties.firstName += `|${firstName}`
        merged[key].properties.lastName += `|${lastName}`
        merged[key].properties.image += `|${image}`
        merged[key].properties.year += `|${year}`
        merged[key].properties.areaOfProfessionalInterest +=
          `|${areaOfProfessionalInterest}`
        merged[key].properties.nominatingInstitution +=
          `|${nominatingInstitution}`
        merged[key].properties.placementInstitution +=
          `|${placementInstitution}`
        merged[key].properties.city += `|${city}`
        merged[key].properties.country += `|${country}`
      } else {
        merged[key] = feature
      }
    })

    const features = Object.keys(merged).map((key) => merged[key])

    // add markers to map
    features.forEach((feature) => {
      const { coordinates } = feature.geometry
      const {
        firstName,
        lastName,
        image,
        year,
        areaOfProfessionalInterest,
        nominatingInstitution,
        placementInstitution,
        city,
        country
      } = feature.properties

      const firstNameParts = firstName.split('|')
      const lastNameParts = lastName.split('|')
      const imageParts = image.split('|')
      const yearParts = year.split('|')
      const areaOfProfessionalInterestParts =
        areaOfProfessionalInterest.split('|')
      const nominatingInstitutionParts = nominatingInstitution.split('|')
      const placementInstitutionParts = placementInstitution.split('|')
      const cityParts = city.split('|')
      const countryParts = country.split('|')

      let html = ''
      for (let i = 0; i < firstNameParts.length; i++) {
        html += '<div class="scholar-info">'
        html += `  <img class="scholar-image" src="${imageParts[i]}">`
        html += '  <div class="scholar-meta">'
        html += `    <h5>${firstNameParts[i]} ${lastNameParts[i]}</h5>`
        html += `    <h5>${yearParts[i]} | ${areaOfProfessionalInterestParts[i]}</h5>`
        html += `    <div class="meta-data-item">${nominatingInstitutionParts[i]}</div>`
        html += `    <div class="meta-data-item">${placementInstitutionParts[i]}</div>`
        html += `    <div class="meta-data-item">${cityParts[i]}, ${countryParts[i]}</div>`
        html += '    <div class="cta-wrapper">'
        html +=
          '      <a href="#" class="scholar-cta">View Scholar Profile<img class="icon link-out" src="/static/images/icon-link-out.png" alt=""></a>'
        html += '    </div>'
        html += '  </div>'
        html += '</div>'
        if (i !== firstNameParts.length - 1) {
          html += '<hr class="mapbox-content-delineator" />'
        }
      }

      // create an HTML element for each feature
      const el = document.createElement('div')
      el.className = 'marker'

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(html)

      // make a marker for each feature and add to the map
      const marker = new mapboxgl.Marker(el)
        .setLngLat(coordinates)
        .setPopup(popup)
        .addTo(map)

      const key = feature.geometry.coordinates.join('|')
      markers[key] = marker

      popup.on('open', (event) => {
        // can reuse above `key`
        const { _element } = markers[key]
        $(_element).addClass('active')
        map.flyTo({
          center: coordinates,
          speed: 0.3
        })
      })
      popup.on('close', (event) => {
        // can reuse above `key`
        const { _element } = markers[key]
        $(_element).removeClass('active')
      })
    })

    const bounds = geojsonExtent(geoJSON)
    // fyi -- format of aboce is [WSEN]
    map.fitBounds(bounds, { padding: 50 })
  }
}

const redrawMap = ({ geoJSON }) => {
  removeMarkers()
  map.removeSource('scholars')
  drawMapMarkers({ geoJSON })
}

const toggleFilters = () => {
  const $trigger = $('.map-filters-trigger')
  $trigger.toggleClass('active')
  const isActive = $trigger.hasClass('active')
  $trigger.html(isActive ? 'Hide Filters' : 'Show Filters')
  $('#map-filters').toggleClass('active')
  $('.map-filters-checkboxes').toggleClass('active')
}

const applyFilters = () => {
  toggleFilters()

  // filter all the features based on the `areaOfProfessionalInterest`
  // note: there can only be one!
  let features = application.scholars.data.features.filter((feature) =>
    areasOfProfessionalInterest.some(
      (interest) => interest === feature.properties.areaOfProfessionalInterest
    )
  )

  if (!features.length) {
    features = application.scholars.data.features
  }

  const geoJSON = {
    type: 'FeatureCollection',
    features
  }

  redrawMap({ geoJSON })
}

const handleFilterClick = ({ filters }) => {
  areasOfProfessionalInterest = filters
}

module.exports = {
  init: function (config) {
    console.log('  === mapbox ===')
    signals = config.signals

    if (typeof application !== 'undefined') {
      // *****************************
      // *** CHANGE FOR PRODUCTION ***
      // *****************************
      mapboxgl.accessToken =
        'pk.eyJ1IjoiYmxlbmRlcmJveCIsImEiOiJjam9nMmNpMmowYW43M3dxdmgyaHg1M3BxIn0.BEzrRvUo_ce8sTc405biaQ'
      map = new mapboxgl.Map({
        container: 'mapbox',
        // *****************************
        // *** CHANGE FOR PRODUCTION ***
        // *****************************
        style: 'mapbox://styles/blenderbox/cjog2dnd004db2sol6xgyxh8h',
        center: [-75.011684, 39.800877]
        // style: 'mapbox://styles/mapbox/streets-v10',
        // style: 'mapbox://styles/mapbox/light-v9',
        // style: 'mapbox://styles/mapbox/basic-v9',
      })

      // adding in some random area of professional interest for testing
      // *****************************
      // *** REMOVE FOR PRODUCTION ***
      // *****************************
      const geoJSON = {
        type: 'FeatureCollection',
        features: application.scholars.data.features.map((feature) => {
          feature.properties.areaOfProfessionalInterest = _.sample(
            application.generic.areasOfProfessionalInterest
          )
          return feature
        })
      }

      map.on('load', () => {
        // *****************************
        // *** CHANGE FOR PRODUCTION ***
        // *****************************
        // drawMapMarkers({ geoJSON: application.scholars.data });
        drawMapMarkers({ geoJSON })
      })

      map.addControl(new mapboxgl.NavigationControl(), 'top-left')

      $('.map-filters-trigger').click(toggleFilters)
      $('.map-filters-apply').click(applyFilters)
    }

    checkbox.init(config)

    signals.onFilterClick.add(handleFilterClick)
    signals['domReady'].dispatch()
  }
}
