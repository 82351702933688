import $ from 'jquery'
import BlenderboxSelect from '../components/blenderbox-select'

var signals

var selectSelector = '.bbox-select'
var selectInstances = []

module.exports = {
  init: function (config) {
    console.log('  === grants-landing ===')
    signals = config.signals

    const $selects = $(selectSelector)
    if ($selects.length) {
      $selects.each((i, el) => {
        selectInstances[i] = new BlenderboxSelect(signals, $(el))
        selectInstances[i].init()
      })
    }

    if (typeof application !== 'undefined') {
      console.log('application', application)
    }

    signals['domReady'].dispatch()
  }
}
