import $ from 'jquery'
import Signal from 'signals'

import expandCollapse from './components/expandCollapse'
import header from './components/header'

import aboutOurMissionPage from './pages/about-our-mission'
import aboutPagePage from './pages/about-page'
import basicPagePage from './pages/basic-page'
import errorPagePage from './pages/error-page'
import fellowshipsLandingPage from './pages/fellowships-landing'
import grantsDetailPage from './pages/grants-detail'
import grantsLandingPage from './pages/grants-landing'
import homePage from './pages/home'
import luceScholarDetailPage from './pages/luce-scholar-detail'
import luceScholarExperienceDetailPage from './pages/luce-scholar-experience-detail'
import luceScholarExperienceLandingPage from './pages/luce-scholar-experience-landing'
import luceScholarsDirectoryPage from './pages/luce-scholars-directory'
import luceScholarsDirectoryResultsPage from './pages/luce-scholars-directory-results'
import mapboxPage from './pages/mapbox'
import newsAndArticlesPage from './pages/news-and-articles'
import newsAndArticlesResultsPage from './pages/news-and-articles-results'
import newsDetailPage from './pages/news-detail'
import newsDetailAltPage from './pages/news-detail-alt'
import programPagePage from './pages/program-page'
import programPageClbPage from './pages/program-page-clb'
import programPageGrantsPage from './pages/program-page-grants'
import programPageLuceScholarsPage from './pages/program-page-luce-scholars'
import programTheologyInquiriesAndGuidelinesPage from './pages/program-theology-inquiries-and-guidelines'
import programsLandingPage from './pages/programs-landing'
import searchResultsPage from './pages/search-results'
import styleguidePage from './pages/styleguide'
import tableOfContentsPage from './pages/table-of-contents'
import thirdLevelPagePage from './pages/third-level-page'

const pageVarJsList = {
  'about-our-mission': aboutOurMissionPage,
  'about-page': aboutPagePage,
  'basic-page': basicPagePage,
  'error-page': errorPagePage,
  'fellowships-landing': fellowshipsLandingPage,
  'grants-detail': grantsDetailPage,
  'grants-landing': grantsLandingPage,
  home: homePage,
  'luce-scholar-detail': luceScholarDetailPage,
  'luce-scholar-experience-detail': luceScholarExperienceDetailPage,
  'luce-scholar-experience-landing': luceScholarExperienceLandingPage,
  'luce-scholars-directory': luceScholarsDirectoryPage,
  'luce-scholars-directory-results': luceScholarsDirectoryResultsPage,
  mapbox: mapboxPage,
  'news-and-articles': newsAndArticlesPage,
  'news-and-articles-results': newsAndArticlesResultsPage,
  'news-detail': newsDetailPage,
  'news-detail-alt': newsDetailAltPage,
  'program-page': programPagePage,
  'program-page-clb': programPageClbPage,
  'program-page-grants': programPageGrantsPage,
  'program-page-luce-scholars': programPageLuceScholarsPage,
  'program-theology-inquiries-and-guidelines':
    programTheologyInquiriesAndGuidelinesPage,
  'programs-landing': programsLandingPage,
  'search-results': searchResultsPage,
  styleguide: styleguidePage,
  'table-of-contents': tableOfContentsPage,
  'third-level-page': thirdLevelPagePage
}

// global configuration object. Passed via init() to all modules
var config = {
  signals: {
    open: new Signal(),
    domReady: new Signal(),
    itemSelected: new Signal(),
    onFilterClick: new Signal(),
    expandCollapse: new Signal()
  }
}

// do this when the dom is ready
function _onDomReady() {
  // @OPTIMIZE page-content-fade-in
  //
  // Fade the page content in as soon as the page is loaded.
  // Disabled for now, why would you do that? It's blocking the view
  // until _everything_ is loaded giving a very bad UX.
  //
  // $('body').addClass('in');
}

// Dynamic Javascript Module loader, for concerns-separated per-page JS inclusion
function autoJsModule() {
  // Is the pageJS variable declared in your route vars? Load it.
  if (typeof pageJs !== 'undefined') {
    const pageModule = pageVarJsList[pageJs]

    // Does the module have an init()? Run it, w/ config included.
    if (pageModule && typeof pageModule.init === 'function') {
      pageModule.init(config)
    }
  }
}

// global functions

function mainNav() {
  $('.menu-control').click(function () {
    $('.header, .main-nav').toggleClass('active')
    $('.links--tier1 li').removeClass('active')
  })
  $('.links--tier1 li a').click(function (e) {
    $('.links--tier1 li').removeClass('active')
    setTimeout(function () {
      $(e.target).parent().addClass('active')
    }, 10)
  })
}

function programCarousel() {
  const generalTarget = '.program-carousel--nav ul li a'
  const target1 = '.program-carousel--nav ul li:nth-of-type(1) a'
  const target2 = '.program-carousel--nav ul li:nth-of-type(2) a'
  $(generalTarget).click(function (e) {
    e.preventDefault()
    $(generalTarget).removeClass('active')
    setTimeout(function () {
      $(e.target).addClass('active')
    }, 10)
  })
  $('.program-carousel--nav ul li:nth-of-type(2) a, div.arrow.right').click(
    function (e) {
      $('.program-carousel--slide:nth-of-type(1)').addClass('inactive')
      $('.program-carousel--slide:nth-of-type(2)').addClass('active')
      $('.program-carousel--slide:nth-of-type(2)').removeClass('hide')
      //
      $('.program-carousel--nav ul li:nth-of-type(1) a').removeClass('active')
      $('.program-carousel--nav ul li:nth-of-type(2) a').addClass('active')
      //
      $('div.arrow.left').addClass('active')
    }
  )
  $('.program-carousel--nav ul li:nth-of-type(1) a, div.arrow.left').click(
    function (e) {
      $('.program-carousel--slide:nth-of-type(1)').removeClass('inactive')
      $('.program-carousel--slide:nth-of-type(2)').removeClass('active')
      $('.program-carousel--slide:nth-of-type(2)').addClass('hide')
      //
      $('.program-carousel--nav ul li:nth-of-type(2) a').removeClass('active')
      $('.program-carousel--nav ul li:nth-of-type(1) a').addClass('active')
      //
      $('div.arrow.left').removeClass('active')
    }
  )
}

function expandSearch() {
  $('.search-bar, .search-trigger-container').addClass('active')
  $('.search-trigger').addClass('inactive')
  $('.search-input').focus()
}

function contractSearch() {
  $('.search-bar, .search-trigger-container').removeClass('active')
  $('.search-trigger').removeClass('inactive')
  $('.search-input').blur()
}

function toggleMenuTrigger() {
  if ($('.mobile-menu-trigger').hasClass('smush')) {
    $('.mobile-menu-trigger').removeClass('cross')
    setTimeout(function () {
      $('.mobile-menu-trigger').removeClass('smush')
    }, 200)
    //
    $('.site-nav').removeClass('show')
  } else {
    $('.mobile-menu-trigger').addClass('smush')
    setTimeout(function () {
      $('.mobile-menu-trigger').addClass('cross')
    }, 200)
    //
    $('.site-nav').addClass('show')
  }
}

function subnavToggle() {
  $('.basic-subnav ul').slideToggle(300)
  $('.basic-subnav .title').toggleClass('open')
}

// Create the global App module
const App = {
  // init the app
  init() {
    mainNav()
    programCarousel()

    console.log('=== init ===')

    expandCollapse.init(config)
    header.init()

    $('body').click(function (event) {
      if (
        $(event.target).hasClass('search-trigger') ||
        $(event.target).hasClass('search-input')
      ) {
        expandSearch()
      } else {
        contractSearch()
      }
    })

    $('.mobile-menu-trigger').click(function () {
      toggleMenuTrigger()
    })

    $('.basic-subnav .title').click(function () {
      if ($(window).width() <= 768) {
        subnavToggle()
      }
    })

    config.signals['domReady'].add(_onDomReady)
    autoJsModule()
  },

  // expose the config
  config: config
}

// kickoff the app
$(App.init)

// hack: Safari cache on back button is buggy. It's cache entire page state including
// the javascript state, but unfortunately it doesn't work on our application. So,
// the solution is just to refresh it.
// see: https://stackoverflow.com/questions/5297122/preventing-cache-on-back-button-in-safari-5
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload()
  }
}

// export App to the browser/console
module.exports = App
