import subNavA11y from './subNavA11y'
import accordion from './accordion'

module.exports = {
  init: function () {
    console.log('  === header ===')
    accordion.init()
    subNavA11y.init()
  }
}
