import $ from 'jquery'
import _ from 'lodash'

function Blenderbox(signals, $el) {
  // props
  var id
  var type
  var collapsableParentLabel

  // state
  var isOpen = false
  var selected
  var title

  // this needs to be a helper/utility function
  const detectActivationEvent = (event) => {
    if (event && event.type === 'keydown') {
      if (event.key !== 'Enter' && event.key !== ' ') {
        return false
      }
    }
    return true
  }

  const isSingleSelect = () => type === 'single-select'
  const isMultiTier = () => type === 'multi-tier'

  const setTitle = () => {
    const dataPlaceholder = $el.attr('data-placeholder')
    let isDefaultState = false
    if (!_.flatten(title).length) {
      isDefaultState = true
      if (dataPlaceholder.length) {
        title = [dataPlaceholder]
      } else {
        title = ['Please make a selection']
      }
    }
    // if multi-tier && collapsableParentLabel, let's roll up the title
    if (isMultiTier() && collapsableParentLabel) {
      const isFilled = title.map(
        (section, i) =>
          selected[i].length === selected[i].filter((n) => n.selected).length
      )
      const labels = $el
        .find('.top-level')
        .children('li')
        .map((i, el) => $(el).attr('id'))
        .get()
      title = isFilled
        .map((filled, i) => {
          if (filled) {
            return [labels[i]]
          }
          return title[i]
        })
        .filter((n) => n)
    }
    if (isDefaultState) {
      $el
        .find('.bbox-select__header .bbox-select__btn')
        .removeClass('default-state')
    } else {
      $el
        .find('.bbox-select__header .bbox-select__btn')
        .addClass('default-state')
    }

    // special case for a specific one :()
    if (
      isSingleSelect() &&
      !isDefaultState &&
      dataPlaceholder.toLowerCase() === 'sort by:'
    ) {
      title[0] = [`${dataPlaceholder} ${title[0]}`]
    }

    $el
      .find('.bbox-select__header .bbox-select__btn')
      .html(_.flatten(title).join(', '))
  }

  const setSelectedItems = () => {
    if (isMultiTier()) {
      // no need to target .nested ???
      $el.find('ul').each((i, ul) => {
        $(ul)
          .find('li .bbox-select__btn')
          .each((ii, el) => {
            if ($(el).data('selected') === true) {
              $(el).addClass('selected')
            } else {
              $(el).removeClass('selected')
            }
          })
      })
    } else {
      $el.find('li .bbox-select__btn').each((i, el) => {
        if ($(el).data('selected') === true) {
          $(el).addClass('selected')
        } else {
          $(el).removeClass('selected')
        }
        // $(el).removeClass('selected');
        // if (selected.includes(i)) {
        //   $(el).addClass('selected');
        // }
      })
    }
  }

  const toggle = () => {
    $el.find('.bbox-select__header').toggleClass('open')
    $el.find('.bbox-select__dropdown').toggleClass('open')
    $el.find('.bbox-select__background').toggleClass('open')
  }

  const handleToggleDropdown = (event) => {
    if (detectActivationEvent(event)) {
      event.stopPropagation()
      event.preventDefault()

      isOpen = !isOpen

      toggle()
    }
  }

  const setAllNestedSelected = ({ index, target, value }) => {
    // first set `selected`
    for (let i = 0; i < selected[index].length; i += 1) {
      selected[index][i].selected = value
    }
    // then set DOM
    target
      .parent()
      .find('.nested')
      .find('li .bbox-select__btn')
      .each((i, el) => {
        $(el).data('selected', value)
        $(el).attr('data-selected', value)
      })
  }

  const handleSelectItem = (event) => {
    if (detectActivationEvent(event)) {
      const $target = $(event.currentTarget)

      event.stopPropagation()
      event.preventDefault()

      if (isMultiTier()) {
        const childIndex = $target.parent().index()
        const $parentLi = $target.parent().parent('.nested').parent('li')
        const $firstChild = $parentLi.children(':first')
        const nestedParentIndex = $parentLi.index()

        // check to see if we are clicking on a child or a parent
        if (nestedParentIndex >= 0) {
          // child
          const child = selected[nestedParentIndex][childIndex]
          child.selected = !child.selected
          $target.data('selected', child.selected)
          $target.attr('data-selected', child.selected)
          const childrenSelected = selected[nestedParentIndex].filter(
            (n) => n.selected
          )
          if (selected[nestedParentIndex].length === childrenSelected.length) {
            $firstChild.data('selected', true)
            $firstChild.attr('data-selected', true)
          } else {
            $firstChild.data('selected', false)
            $firstChild.attr('data-selected', false)
          }
        } else {
          // parent
          const index = $target.parent().index()
          const childrenSelected = selected[index].filter((n) => n.selected)

          if (selected[index].length === childrenSelected.length) {
            $target.data('selected', false)
            $target.attr('data-selected', false)

            setAllNestedSelected({
              index,
              target: $target,
              value: false
            })
          } else {
            $target.data('selected', true)
            $target.attr('data-selected', true)

            setAllNestedSelected({
              index,
              target: $target,
              value: true
            })
          }
        }

        title = selected.map((ul) =>
          ul
            .map((li) => {
              if (li.selected) {
                return li.label
              }
              return null
            })
            .filter((n) => n)
        )
      } else {
        const ss = isSingleSelect()
        const index = $target.parent().index()
        const label = $target.html()
        // not in love with this if statement
        if (ss) {
          // selected first ...
          for (let i = 0; i < selected.length; i += 1) {
            if (i === index) {
              // toggle chosen one
              selected[i].selected = !selected[i].selected
            } else {
              selected[i].selected = false
            }
          }

          // ... then DOM
          $target
            .parent()
            .parent('.top-level')
            .find('li .bbox-select__btn')
            .each((i, el) => {
              $(el).data('selected', selected[i].selected)
              $(el).attr('data-selected', selected[i].selected)
            })

          // single select, close now
          toggle()
        } else {
          // only toggle this one
          selected[index].selected = !selected[index].selected
          $target.data('selected', selected[index].selected)
          $target.attr('data-selected', selected[index].selected)
        }

        title = selected
          .map((li) => {
            if (li.selected) {
              return li.label
            }
            return null
          })
          .filter((n) => n)

        isOpen = !ss
      }

      setSelectedItems()
      setTitle()

      signals.itemSelected.dispatch({ id, type, selected: _.flatten(selected) })
    }
  }

  const init = () => {
    id = $el.attr('id')
    type = $el.data('type')
    collapsableParentLabel = $el.data('collapsable-parent-label')
    // String() because if we pass in [0], it can't split on the int
    // selected = String($el.data('selected')).split(',').map(Number);
    selected = []
    if (isMultiTier()) {
      $el.find('ul.nested').each((i, nested) => {
        const selectedItems = $(nested)
          .find('li .bbox-select__btn')
          .map((ii, el) => ({
            label: $(el).html(),
            selected: $(el).data('selected')
          }))
          .get()

        selected.push(selectedItems)
      })

      title = selected.map((item) =>
        item
          .map((li) => {
            if (li.selected) {
              return li.label
            }
            return null
          })
          .filter((n) => n)
      )
    } else {
      selected = $el
        .find('li .bbox-select__btn')
        .map((i, el) => ({
          label: $(el).html(),
          selected: $(el).data('selected')
        }))
        .get()

      title = selected
        .map((li) => {
          if (li.selected) {
            return li.label
          }
          return null
        })
        .filter((n) => n)
    }

    $el.find('.bbox-select__header').click(handleToggleDropdown)
    $el.find('.bbox-select__header').keydown(handleToggleDropdown)

    $el.find('li .bbox-select__btn').click(handleSelectItem)
    $el.find('li .bbox-select__btn').keydown(handleSelectItem)

    $el.find('.bbox-select__background').click(handleToggleDropdown)

    setSelectedItems()
    setTitle()
  }

  return {
    init
  }
}

module.exports = Blenderbox
