import $ from 'jquery'
import stickyProgramHeader from '../components/programHeader'

var signals

module.exports = {
  init: function (config) {
    console.log('  === program-theology-inquiries-and-guidelines ===')
    signals = config.signals
    stickyProgramHeader.init(config)
    signals['domReady'].dispatch()
  }
}
