import $ from 'jquery'

var signals

// require('waypoints/lib/jquery.waypoints.min');

const expandCollapseTrigger = '.expand-collapse'
// const expandCollapseContainer = '.expand-collapse-content';
const expandDuration = 300

const handleClick = (event) => {
  const $target = $(event.currentTarget)
  const expandTarget = $target.data('target')
  if ($target.hasClass('active')) {
    // $target.prev().slideUp(expandDuration);
    $(expandTarget).slideUp(expandDuration)
    $target.removeClass('active')
    $target.children('.label--expand, .label--collapse').toggleClass('hide')
  } else {
    // $target.prev().slideDown(expandDuration);
    $(expandTarget).slideDown(expandDuration)
    $target.addClass('active')
    $target.children('.label--expand, .label--collapse').toggleClass('hide')
  }

  // // recalculate waypoints
  // setTimeout( function() {
  //   Waypoint.refreshAll();
  // }, expandDuration);

  // make use of our signal if we want
  // (perhaps for waypoint issue?)
  signals.expandCollapse.dispatch({ expandDuration })
}

module.exports = {
  init: function (config) {
    console.log('  === expand-collapse ===')
    $(expandCollapseTrigger).click(handleClick)
    signals = config.signals
  }
}
