var geojsonCoords = require('geojson-coords'),
    traverse = require('traverse'),
    extent = require('extent');

module.exports = function(_) {
    return getExtent(_).bbox();
};

module.exports.polygon = function(_) {
    return getExtent(_).polygon();
};

module.exports.bboxify = function(_) {
    return traverse(_).map(function(value) {
        if (value && typeof value.type === 'string') {
            value.bbox = getExtent(value).bbox();
            this.update(value);
        }
    });
};

function getExtent(_) {
    var bbox = [Infinity, Infinity, -Infinity, -Infinity],
        ext = extent(),
        coords = geojsonCoords(_);
    for (var i = 0; i < coords.length; i++) ext.include(coords[i]);
    return ext;
}
