import $ from 'jquery'
import { log } from 'util'

var signals

module.exports = {
  init: function (config) {
    console.log('  === grants-detail ===')
    signals = config.signals
    signals['domReady'].dispatch()
  }
}
