import $ from 'jquery'

let $allButtons
let $allSubnavWrapperListItems

const handleIn = (event) => {
  const $target = $(event.currentTarget)
  $target.addClass('open')
  $target.find('a[aria-haspopup="true"]').attr('aria-expanded', 'true')
  $target.find('button').attr('aria-expanded', 'true')
  $target.find('.basic-subnav').attr('aria-hidden', 'false')
}

const handleOut = (event) => {
  const $target = $(event.currentTarget)
  $target.removeClass('open')
  $target.find('a[aria-haspopup="true"]').attr('aria-expanded', 'false')
  $target.find('button').attr('aria-expanded', 'false')
  $target.find('.basic-subnav').attr('aria-hidden', 'true')
}

const handleFlyoutClick = (event) => {
  const $parent = $(event.currentTarget).parent('.subnav-wrapper')
  if (!$parent.hasClass('open')) {
    $parent.addClass('open')
    $parent.find('a[aria-haspopup="true"]').attr('aria-expanded', 'true')
    $parent.find('button').attr('aria-expanded', 'true')
    $parent.find('.basic-subnav').attr('aria-hidden', 'false')
  } else {
    $parent.removeClass('open')
    $parent.find('a[aria-haspopup="true"]').attr('aria-expanded', 'false')
    $parent.find('button').attr('aria-expanded', 'false')
    $parent.find('.basic-subnav').attr('aria-hidden', 'true')
  }
}

const handleFocus = (event) => {
  // close all subnavs and other related elements
  const $subNav = $(event.currentTarget).closest('.subnav-wrapper.open')
  if (!$subNav.length) {
    $allSubnavWrapperListItems.removeClass('open')
    $allSubnavWrapperListItems
      .find('a[aria-haspopup="true"]')
      .attr('aria-expanded', 'false')
    $allSubnavWrapperListItems.find('button').attr('aria-expanded', 'false')
    $allSubnavWrapperListItems.find('.basic-subnav').attr('aria-hidden', 'true')
  }
}

module.exports = {
  init: function () {
    console.log('  === secondaryNavA11y ===')

    $allButtons = $('.secondary-nav-a11y')
    $allSubnavWrapperListItems = $('.subnav-wrapper')

    $allButtons.click(handleFlyoutClick)
    $('.header a').focus(handleFocus)

    $allSubnavWrapperListItems.hover(handleIn, handleOut)
  }
}
