import $ from 'jquery'
import _ from 'lodash'

var signals
let filters = []

const filter = (event) => {
  const target = $(event.currentTarget)
  const value = target.val()
  filters = _.xor(filters, [value])
  signals.onFilterClick.dispatch({ filters })
}

module.exports = {
  init: function (config) {
    console.log('  === checkbox ===')
    signals = config.signals

    $('input[type="checkbox"][data-function="filter"]').change(filter)
  }
}
