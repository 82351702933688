import $ from 'jquery'
import stickyProgramHeader from '../components/programHeader'
import mapbox from '../components/mapbox'

var signals

window.jQuery = window.$ = $

require('waypoints/lib/jquery.waypoints')
require('waypoints/lib/shortcuts/sticky')

const Flickity = require('flickity')

const flickContainer = '.luce-scholars-slides-container'
const flickSlide = '.luce-scholars-slide'

const handleExpandCollapse = ({ expandDuration }) => {
  setTimeout(function () {
    Waypoint.refreshAll()
  }, expandDuration)
}

module.exports = {
  init: function (config) {
    console.log('  === program-page-luce-scholars ===')
    signals = config.signals

    if (typeof application !== 'undefined') {
      // console.log('application', application.scholars.data);
      if ($('#mapbox').length) {
        mapbox.init({
          config,
          data: application.scholars.data,
          currentYear: application.generic.currentYear
        })
      }
    }

    if ($('.maps-wrapper').length) {
      const mapTop = new Waypoint({
        element: $('.maps-wrapper')[0],
        handler: function (direction) {
          $('.program-title').addClass('hide')
          if (direction === 'down') {
            $('.program-title').addClass('hide')
          } else {
            $('.program-title').removeClass('hide')
          }
        },
        offset: 270
      })
    }

    if ($('.mixed-layout + article').length) {
      const mapBottom = new Waypoint({
        element: $('.mixed-layout + article')[0],
        handler: function (direction) {
          if (direction === 'down') {
            $('.program-title').removeClass('hide')
          } else {
            $('.program-title').addClass('hide')
          }
        },
        offset: 190
      })
    }

    stickyProgramHeader.init(config)

    const elem = document.querySelector(flickContainer)
    if (elem) {
      const flkty = new Flickity(elem, {
        // options
        contain: false,
        cellSelector: flickSlide,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'left',
        groupCells: 2
      })

      // arrow logic
      $('.arrow.left').on('click', function () {
        // prev
        flkty.previous()
        // toggle disabled state
        if ($('.luce-scholars-slide:first-of-type').hasClass('is-selected')) {
          $('.arrow.left').addClass('disabled')
          $('.arrow.right').removeClass('disabled')
        } else {
          $('.arrow.right').removeClass('disabled')
        }
      })
      $('.arrow.right').on('click', function () {
        // next
        flkty.next()
        // toggle disabled state
        if (
          $('.luce-scholars-slide:nth-last-child(2)').hasClass('is-selected')
        ) {
          $('.arrow.right').addClass('disabled')
          $('.arrow.left').removeClass('disabled')
        } else {
          $('.arrow.left').removeClass('disabled')
        }
      })

      // Hack: for horizontal cutoff
      //
      // Look like flickity need to wait the browser fully paint the layout, so it
      // can calculate the position correctly. So, we wait until next animation frame
      requestAnimationFrame(function () {
        flkty.reposition()
      })
    }

    signals.expandCollapse.add(handleExpandCollapse)
    signals['domReady'].dispatch()
  }
}
