import $ from 'jquery'
import stickyProgramHeader from '../components/programHeader'

var signals

function scrollInto(anchor) {
  var elem = document.getElementById(anchor)
  // according to spec, the anchor can also name=anchor
  if (!elem) {
    elem = document.querySelector(`[name=${anchor}]`)
  }

  if (elem) {
    elem.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
}

module.exports = {
  init: function (config) {
    console.log('  === program-page ===')
    signals = config.signals
    if (window.location.hash !== '') {
      scrollInto(window.location.hash.slice(1))
    }
    stickyProgramHeader.init(config)
    signals['domReady'].dispatch()
  }
}
