import $ from 'jquery'

const Flickity = require('flickity')

var signals

const taglines = []

const setTagline = ({ index }) => {
  $('h1.tagline, .tagline__mobile h1').html(taglines[index])
}

const handleReady = () => {
  setTagline({
    index: 0
  })
}

const handleChange = (index) => {
  $('h1.tagline, .tagline__mobile').addClass('hide')
}

const handleSettle = (index) => {
  setTagline({ index })
  $('h1.tagline, .tagline__mobile').removeClass('hide')
}

module.exports = {
  init: function (config) {
    console.log('  === homepage-carousel ===')

    const elem = document.querySelector('.billboard-images')

    // No billboard on this page.
    if (elem === null) {
      return
    }

    const flkty = new Flickity(elem, {
      // options
      contain: true,
      cellSelector: '.billboard-image',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      autoPlay: 5000,
      on: {
        ready: handleReady
      }
    })

    $('.billboard-image').each((index, el) => {
      taglines.push($(el).data('tagline'))
    })
    flkty.on('change', handleChange)
    flkty.on('settle', handleSettle)

    signals = config.signals
  }
}
